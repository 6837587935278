<script setup lang="ts">

</script>

<template>
  <div h-full overflow-hidden pos-relative>
    <div pos-absolute pos-top-0 pos-left-0 w-full h-full overflow-hidden>
      <slot />
    </div>
  </div>
</template>
